<template>
  <bkf-icon :class="state">
    <v-tooltip
      v-if="content"
      bottom
    >
      <template #activator="{ on, attrs }">
        <!-- eslint-disable max-len -->
        <svg
          viewBox="0 0 512 512"
          style="enable-background:new 0 0 512 512;"
          v-bind="attrs"
          :width="size"
          :height="size"
          v-on="on"
        >
          <g>
            <g>
              <path
                d="M338.722,115.086c-6.343-3.732-14.513-1.611-18.244,4.733s-1.613,14.513,4.733,18.244
                c41.646,24.492,67.517,69.683,67.517,117.937c0,75.394-61.335,136.729-136.729,136.729c-75.393,0-136.729-61.337-136.729-136.729
                c0-48.254,25.87-93.445,67.517-117.937c6.345-3.73,8.464-11.899,4.733-18.244c-3.73-6.345-11.898-8.462-18.244-4.733
                C123.523,144.347,92.616,198.342,92.616,256c0,90.091,73.293,163.384,163.384,163.384S419.384,346.091,419.382,256
                C419.382,198.343,388.475,144.347,338.722,115.086z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M256,74.832c-7.361,0-13.328,5.968-13.328,13.328V256c0,7.361,5.967,13.328,13.328,13.328
                c7.361,0,13.328-5.967,13.328-13.328V88.16C269.328,80.801,263.361,74.832,256,74.832z"
              />
            </g>
          </g>
        </svg>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>
  </bkf-icon>
</template>

<script>
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en';
import { distanceInWords } from 'date-fns';
import LocaleManager from '@services/locale';

export default {
  props: {
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
    state: {
      default: null,
      required: false,
      type: String,
    },
    size: {
      type: String,
      default: '24px',
    },
  },
  computed: {
    content() {
      const now = new Date();
      const locale = this.lang === 'pl' ? pl : en;
      let deviceLastSync = new Date(this.lastSync);

      /** Date is not UTC */
      if (this.lastSync && this.lastSync.search('T') !== -1) {
        deviceLastSync = this.convertDateToUTC(deviceLastSync);
      }

      now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
      return this.lastSync
        ? `${this.$trans('sensor.last-active')}: ${distanceInWords(now, deviceLastSync, { addSuffix: true, locale })}`
        : `${this.$trans('sensor.last-active')}: ${this.$trans('sensor.no-data')}`;
    },
    lang() {
      const localeManager = LocaleManager.get();
      return localeManager.$language;
    },
  },
  methods: {
    convertDateToUTC(date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );
    },
  },
};
</script>
