export default [
  {
    name: 'login',
    path: '/login',
    component: () => import('@components/login/LoginForm'),
    meta: { requiresAuth: false },
  },
  {
    name: 'reset',
    path: '/reset',
    component: () => import('@components/login/ResetForm'),
    meta: { requiresAuth: false },
  },
  {
    name: 'resetting',
    path: '/resetting',
    component: () => import('@components/login/ResettingForm'),
    meta: { requiresAuth: false },
  },
  {
    name: 'terms',
    path: '/terms',
    component: () => import('@components/login/Terms'),
    meta: { requiresAuth: false },
  },
  {
    name: 'terms_en',
    path: '/terms_en',
    component: () => import('@components/login/TermsEn'),
    meta: { requiresAuth: false },
  },
];
