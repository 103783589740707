<template>
  <bkf-icon :class="state">
    <v-tooltip
      v-if="content"
      bottom
    >
      <template #activator="{ on, attrs }">
        <svg
          viewBox="0 0 32 32"
          v-bind="attrs"
          :width="size"
          :height="size"
          v-on="on"
        >
          <!-- eslint-disable max-len -->
          <g stroke="none">
            <path
              fill-opacity=".118"
              d="m21.7 13.2c-1.6 0.2-3.2 1-3.8 3.2-1.2 4.5-1.1 9.2-7.5 10.5-0.1 0-0.2 0-0.3 0.1v0.2c0 0.8 0.5 1.3 1.2 1.3h9.2c0.7 0 1.2-0.6 1.2-1.3v-14z"
            />
            <path
              fill-opacity=".0784"
              d="m20.9 6.51c-2.7 0.73-5.1 2.04-5.4 4.39-0.5 5.5-1.5 8.8-5.4 12.9v3.4c0 0.8 0.5 1.3 1.2 1.3h9.2c0.7 0 1.2-0.6 1.2-1.3v-19.5c0-0.54-0.4-1.02-0.8-1.19z"
            />
          </g>
          <path
            stroke-linecap="round"
            stroke-width="1.5"
            fill="none"
            d="m18.3 24.5h-4.75m2.31-10.2v-4.75m2.38 2.38h-4.75m6.1-6.28v-1.41c0-0.601-0.387-1.09-0.867-1.09h-5.89c-0.48 0-0.867 0.485-0.867 1.09v1.41m-0.823 0.819 9.25-0.0106c0.657-0.000751 1.19 0.579 1.19 1.3l0.0168 19.5c0.000622 0.721-0.528 1.3-1.19 1.3l-9.25 0.0106c-0.657 0.000751-1.19-0.579-1.19-1.3l0.1-19.4c0-0.77 0.5-1.36 1.1-1.36z"
          />
        </svg>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>
  </bkf-icon>
</template>

<script>
export default {
  props: {
    state: {
      default: null,
      required: false,
      type: String,
    },
    level: {
      default: null,
      required: false,
      type: [Number, String],
    },
    size: {
      type: String,
      default: '24px',
    },
  },
  computed: {
    content() {
      return this.$trans(
        'device.indicator.battery-voltage',
        this.state === null ? t`device.indicator.no-data` : `${this.level}V`,
      );
    },
  },
};
</script>
