import deep from '../utils/deep';

/**
 * Path generation plugin
 */
export default {
  install(Vue) {
    Vue.prototype.$deep = deep;
  },
};
