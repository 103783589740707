<template>
  <div style="display: contents">
    <bkf-action
      :tooltip="$trans('action-button.delete')"
      @click="confirmDelete"
    >
      <v-icon> delete </v-icon>
    </bkf-action>

    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog = false"
          >
            {{ $trans('dialog.cancel') }}
          </v-btn>
          <v-btn
            color="red"
            dark
            @click="confirm"
          >
            {{ $trans('dialog.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => t`widget.title`,
    },
    message: {
      type: String,
      required: true,
      default: () => t`widget.message`,
    },

  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirmDelete() {
      this.dialog = true;
    },
    confirm() {
      this.dialog = false;
      this.$emit('click');
    },
  },
};
</script>
