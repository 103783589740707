<template>
  <v-tour
    :name="currentGuide.name"
    :steps="currentGuide.steps"
    :class="{'v-tour-mobile': isMobile}"
  >
    <template slot-scope="tour">
      <!-- eslint-disable vue/no-use-v-if-with-v-for vue/no-v-html-->
      <v-step
        v-for="(step, index) of tour.steps"
        v-if="tour.currentStep === index"
        :key="step.id"
        :step="step"
        :previous-step="tour.previousStep"
        :next-step="tour.nextStep"
        :stop="tour.stop"
        :is-first="tour.isFirst"
        :is-last="tour.isLast"
        :labels="{}"
        :class="`guide-step-${tour.currentStep}`"
      >
        <div
          v-if="step.header"
          slot="header"
          class="v-step__header"
        >
          <div
            v-if="step.header.title"
            v-html="$trans(step.header.title)"
          />
        </div>
        <div
          slot="content"
          class="v-step__content"
        >
          <div
            v-if="step.content"
            v-html="$trans(step.content)"
          />
        </div>
        <div
          slot="actions"
        >
          <div class="flex flex-align-items-center flex-justify-center">
            <bkf-action-previous-page
              :disabled="tour.isFirst"
              @click="previous(tour, step)"
            >
              {{ 'guide.previous'|trans }}
            </bkf-action-previous-page>
            <span>{{ index + 1 }} / {{ currentGuide.steps.length }}</span>
            <bkf-action-next-page
              :disabled="tour.isLast"
              @click="next(tour, step)"
            >
              {{ 'guide.next'|trans }}
            </bkf-action-next-page><bkf-action-close @click="stop(tour)">
              {{ 'guide.close'|trans }}
            </bkf-action-close>
          </div>
        </div>
      </v-step>
    </template>
  </v-tour>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import settings from '@mixins/misc/settings';

const guides = new Map();

export default {
  mixins: [
    settings,
  ],
  data() {
    return {
      currentGuide: null,
      guides,
    };
  },
  computed: {
    ...mapGetters({
      menuVisible: 'menu/visible',
    }),
    stepsCount() {
      return this.currentGuide.steps.length;
    },
  },
  created() {
    const global = {
      name: 'global',
      hooks: {
        start: async () => {
          this.showMenu();
          if (this.$router.currentRoute.name !== 'dashboard') {
            await this.$router.push({ name: 'dashboard' });
          }
          await this.$sleep(300);
        },
        next: async (currentStep) => {
          if (currentStep === 4 && this.isMobile) {
            this.hideMenu();
            await this.$sleep(300);
          }
        },
        previous: async (currentStep) => {
          if (currentStep === 3 && this.isMobile) {
            this.showMenu();
            await this.$sleep(300);
          }
        },
      },
      steps: [
        {
          id: 1,
          target: '.main-menu .nav-section-manage',
          content: 'guide.manage.content',
          header: {
            title: 'guide.manage.title',
          },
          params: {
            placement: 'bottom',
          },
        },
        {
          id: 2,
          target: '.main-menu a[href="#/reports"]',
          content: 'guide.reports.content',
          header: {
            title: 'guide.reports.title',
          },
          params: {
            placement: 'bottom',
          },
        },
        {
          id: 3,
          target: '.main-menu a[href="#/alerts/alerts"]',
          content: 'guide.alerts.content',
          header: {
            title: 'guide.alerts.title',
          },
          params: {
            placement: 'bottom',
          },
        },
        {
          id: 4,
          target: '.main-menu a[href="#/diagnostics"]',
          content: 'guide.diagnostics.content',
          header: {
            title: 'guide.diagnostics.title',
          },
          params: {
            placement: 'bottom',
          },
        },
        {
          id: 5,
          target: '.main-menu a[href="#/dashboard"]',
          content: 'guide.dashboard.content',
          header: {
            title: 'guide.dashboard.title',
          },
          params: {
            placement: this.isMobile ? 'left' : 'top',
          },
        },
        {
          id: 6,
          target: '.v-app-bar .header-user-button',
          content: 'guide.profile.content',
          header: {
            title: 'guide.profile.title',
          },
          params: {
            placement: 'bottom',
          },
        },
      ],
    };

    guides.set('global', global);
    this.currentGuide = global;
  },
  async mounted() {
    const guide = await this.getSetting('guide');

    if (!guide) {
      this.start('global');
      this.setSetting('guide', 'showed');
    }
  },
  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggle',
      showMenu: 'menu/show',
      hideMenu: 'menu/hide',
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
    register(guide) {
      guides.set(guide.name, guide);
    },
    /**
     * Start guide
     */
    async start(name) {
      this.currentGuide = guides.get(name);
      const { hooks } = this.currentGuide;

      if (hooks && hooks.start) {
        await hooks.start(0, this);
      }

      this.changeHighlight(0, -1);

      this.$nextTick(() => {
        this.$tours.global.start();
      });
    },
    /**
     * Stop guide callback
     * @param {{}} tour object
     */
    async stop(tour) {
      const { hooks } = this.currentGuide;

      if (hooks && hooks.stop) {
        await hooks.stop(tour.currentStep, this);
      }

      this.changeHighlight(-1, tour.currentStep);
      tour.stop();
    },
    /**
     * Next step of guide callback
     * @param {{}} tour object
     */
    async next(tour) {
      const { hooks, steps } = this.currentGuide;

      if (tour.currentStep + 1 === steps.length) {
        tour.stop();
      }

      if (hooks && hooks.next) {
        await hooks.next(tour.currentStep + 1, this);
      }

      this.changeHighlight(tour.currentStep + 1, tour.currentStep);
      tour.nextStep();
    },
    /**
     * Previous step of guide callback
     * @param {{}} tour object
     */
    async previous(tour) {
      const { hooks } = this.currentGuide;

      if (hooks && hooks.previous) {
        await hooks.previous(tour.currentStep - 1, this);
      }

      this.changeHighlight(tour.currentStep - 1, tour.currentStep);
      tour.previousStep();
    },
    /**
     * Change highlight of element
     * @param {number} to New index of step
     * @param {number} from Old index of step
     */
    async changeHighlight(to, from) {
      const { currentGuide } = this;

      // Check if browser is based on gecko engine
      const isGeckoEngine = navigator.userAgent.includes('Gecko/');

      // Check if guide started
      if (from > -1) {
        const step = currentGuide.steps[from];
        document.querySelector(step.target).classList.remove('guide-highlight');
      }

      // Check if guide stopped
      if (to > -1) {
        const step = currentGuide.steps[to];
        if (typeof step === 'undefined') return;

        document.querySelector(step.target).classList.add('guide-highlight');
      }

      // Fix for scrolling on Gecko engine (doesn't detect covering by fixed elements)
      if (to === 4 && currentGuide.name === 'global' && isGeckoEngine) {
        document.querySelector('.bkf-container').scrollIntoView({ behavior: 'smooth' });
      }

      // This is ugly hack to vue-tour && gecko engine bug
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
      // block should be 'center' as default, but it's not.
      // In Step component of vue-tour, scrollIntoView is called without 'block' field
      if (to > -1) {
        const element = document.querySelector(currentGuide.steps[to].target);
        // Force srcoll two times
        await this.$sleep(1);
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        await this.$sleep(10);
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
};
</script>

<style lang="stylus" src="./Guide.styl"></style>
