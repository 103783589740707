import axios from 'axios';
import oauth from './oauth';

const api = axios.create({
  baseURL: '', // Ustawiamy później dynamicznie
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Ustawienie bazy API dynamicznie (bez pluginów)
 */
export const initApi = () => {
  const baseUrl = CONFIG.url.api;
  api.defaults.baseURL = `${baseUrl}/api`;

  // Automatyczne odświeżanie tokenów na 401
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const success = await oauth.refreshToken();
        if (success) {
          const newToken = oauth.getAccessToken();
          api.defaults.headers.Authorization = `Bearer ${newToken}`;
          error.config.headers.Authorization = `Bearer ${newToken}`;
          return api.request(error.config); // Ponowna próba żądania
        }
        oauth.logout();
      }
      return Promise.reject(error);
    },
  );

  // Ustawienie nagłówka autoryzacji jeśli token istnieje
  const token = oauth.getAccessToken();
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
};

export default api;
