import colors from './colors';
import menu from './menu';
import models from './models';
import size from './size';

export default {
  colors,
  menu,
  models,
  size,
};
