import api from '@services/api';

export default {
  methods: {
    async onExport({
      url,
      filetype,
      filename,
      params = {},
    }) {
      const { status, data } = await api.get(
        url,
        { params, responseType: 'blob' },
      );

      if (status === 200) {
        const blob = new Blob([data], { type: filetype });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
  },
};
