<template>
  <div style="display: contents">
    <bkf-action
      :tooltip="$trans('action-button.duplicate')"
      @click="confirmDuplicate"
    >
      <v-icon> mdi-book-plus-multiple </v-icon>
    </bkf-action>

    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-text-field
          v-if="withNameField"
          v-model="newName"
          class="mx-6"
          small
          :label="$trans('action-button.new-name')"
        />

        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog = false"
          >
            {{ $trans('dialog.cancel') }}
          </v-btn>
          <v-btn
            color="blue"
            dark
            @click="confirm"
          >
            {{ $trans('dialog.duplicate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => t`widget.title`,
    },
    message: {
      type: String,
      required: true,
      default: () => t`widget.message`,
    },
    nameValue: {
      type: String,
      required: false,
      default: null,
    },
    withNameField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newName: this.nameValue,
      dialog: false,
    };
  },
  methods: {
    confirmDuplicate() {
      this.dialog = true;
    },
    confirm() {
      this.$emit('click', this.newName);
      this.dialog = false;
    },
  },
};
</script>
