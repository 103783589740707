<template>
  <bkf-icon :class="state">
    <v-tooltip
      v-if="content"
      bottom
    >
      <template #activator="{ on, attrs }">
        <!-- eslint-disable max-len -->
        <svg
          viewBox="0 -960 960 960"
          style="enable-background:new 0 0 512 512;"
          :width="size"
          :height="size"
          v-bind="attrs"
          v-on="on"
        >
          <g>
            <g>
              <path
                d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"
              />
            </g>
          </g>
        </svg>
      </template>

      <span>{{ content }}</span>
    </v-tooltip>
  </bkf-icon>
</template>

<script>
export default {
  components: {

  },
  props: {
    level: {
      required: true,
      type: String,
    },
    content: {
      required: true,
      type: String,
    },
    size: {
      type: String,
      default: '24px',
    },
  },
  computed: {
    state() {
      return `icon-${this.level}`;
    },
  },
};
</script>
