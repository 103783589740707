import deep from './deep';
import model from './model';
import notify from './notify';
import sleep from './sleep';
import translate from './trans';

/**
 * Install global plugins
 */
export default {
  install(Vue) {
    // Use plugins
    Vue.use(deep);
    Vue.use(model);
    Vue.use(notify);
    Vue.use(sleep);
    Vue.use(translate);
  },
};
