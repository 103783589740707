import { render, staticRenderFns } from "./AlertBadge.vue?vue&type=template&id=5a6dc9c0&scoped=true&functional=true"
var script = {}
import style0 from "./AlertBadge.styl?vue&type=style&index=0&id=5a6dc9c0&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5a6dc9c0",
  null
  
)

export default component.exports