export default [
  {
    name: 'default',
    path: '/',
    redirect: 'dashboard',
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import('@pages/Dashboard'),
    meta: { title: 'menu.dashboard', requiresAuth: true, icon: 'home' },
  },
  {
    name: 'diagnostics',
    path: '/diagnostics',
    component: () => import('@pages/Diagnostics'),
    meta: { title: 'menu.monitor', requiresAuth: true, icon: 'timeline' },
  },
  {
    name: 'location',
    path: '/location',
    component: () => import('@pages/DevicesLocation'),
    meta: { title: 'menu.devices-localization', requiresAuth: true, icon: 'map' },
  },
  {
    path: '/alerts',
    component: () => import('@pages/Alerts'),
    meta: { title: 'menu.alerts', requiresAuth: true, icon: 'notifications' },
    children: [
      { path: '', redirect: 'alert-rules' },
      {
        name: 'alerts-list',
        path: 'list',
        component: () => import('@pages/Alerts'),
        meta: { title: 'menu.alert-list', requiresAuth: true },
      },
      {
        name: 'alert',
        path: ':id',
        component: () => import('@pages/Alerts/Alert'),
        meta: { title: 'menu.alert', requiresAuth: true },
      },
      {
        name: 'alert-rules',
        path: 'alert-rules',
        component: () => import('@pages/Alerts/AlertRules'),
        meta: { title: 'menu.alert-rules', requiresAuth: true },
      },
      {
        name: 'alert-rule',
        path: 'alert-rules/:id',
        component: () => import('@pages/Alerts/AlertRule'),
        meta: { title: 'menu.alert-rule', requiresAuth: true },
      },
    ],
  },
  {
    name: 'production',
    path: '/production',
    component: () => import('@pages/Production'),
    meta: {
      title: 'menu.production',
      requiresAuth: true,
      icon: 'description',
    },
  },
  {
    name: 'reports',
    path: '/reports',
    component: () => import('@pages/Reports'),
    meta: { title: 'menu.reports', requiresAuth: true, icon: 'description' },
    children: [
      {
        name: 'efficiency-report',
        path: 'efficiency-report',
        component: () => import('@pages/Reports/Reports/EfficiencyReport'),
        meta: { title: 'report.list.efficiency-report.title', requiresAuth: true },
      },
      {
        name: 'device-operators-report',
        path: 'device-operators-report',
        component: () => import('@pages/Reports/Reports/DeviceOperatorsReport'),
        meta: { title: 'report.list.device-operators-report.title', requiresAuth: true },
      },
      {
        name: 'device-work-details-report',
        path: 'device-work-details-report',
        component: () => import('@pages/Reports/Reports/DeviceOperatorsDetailedReport'),
        meta: { title: 'report.list.device-work-details-report.title', requiresAuth: true },
      },
      {
        name: 'device-work-device-shift',
        path: 'device-work-device-shift',
        component: () => import('@pages/Reports/Reports/ShiftsReport'),
        meta: { title: 'report.list.device-work-device-shift.title', requiresAuth: true },
      },
      {
        name: 'device-work-shift-chart',
        path: 'device-work-shift-chart',
        component: () => import('@pages/Reports/Reports/ShiftListReport'),
        meta: { title: 'report.list.device-work-shift-chart.title', requiresAuth: true },
      },
      {
        name: 'production-device-work-matrix',
        path: 'production-device-work-matrix',
        component: () => import('@pages/Reports/Reports/ProductionDeviceWorkMatrix.vue'),
        meta: { title: 'report.list.production-device-work-matrix.title', requiresAuth: true },
      },
    ],
  },
  {
    path: '/manage',
    component: () => import('@pages/Manage/Manage'),
    meta: { title: 'menu.manage', requiresAuth: true, icon: 'settings' },
    children: [
      {
        name: 'devices',
        path: 'devices',
        component: () => import('@pages/Manage/Devices'),
        meta: { title: 'menu.devices', requiresAuth: true },
      },
      {
        name: 'device',
        path: 'device/:id',
        component: () => import('@pages/Manage/Devices/Device'),
        meta: { title: 'menu.device-details', requiresAuth: true },
        props: true,
      },
      {
        name: 'device-groups',
        path: 'device-groups',
        component: () => import('@pages/Manage/DeviceGroups'),
        meta: { title: 'menu.device-groups', requiresAuth: true },
      },
      {
        name: 'device-group',
        path: 'device-group/:id',
        component: () => import('@pages/Manage/DeviceGroups/DeviceGroup'),
        meta: { title: 'menu.device-group-details', requiresAuth: true },
        props: true,
      },
      {
        name: 'device-operators',
        path: 'device-operators',
        component: () => import('@pages/Manage/Workers'),
        meta: { title: 'menu.device-operators', requiresAuth: true },
      },
      {
        name: 'device-operator',
        path: 'device-operator/:id',
        component: () => import('@pages/Manage/Workers/Worker'),
        meta: { title: 'menu.device-operator-details', requiresAuth: true },
        props: true,
      },
      {
        name: 'device-operator-groups',
        path: 'device-operator-groups',
        component: () => import('@pages/Manage/WorkerGroups'),
        meta: { title: 'menu.device-operator-groups', requiresAuth: true },
      },
      {
        name: 'device-operator-group',
        path: 'device-operator-groups/:id',
        component: () => import('@pages/Manage/WorkerGroups/WorkerGroup'),
        meta: { title: 'menu.device-operator-group-details', requiresAuth: true },
        props: true,
      },
    ],
  },
  {
    name: 'users',
    path: '/users',
    component: () => import('@pages/Users'),
    meta: { title: 'menu.users', requiresAuth: true },
  },
  {
    path: '/administration',
    component: () => import('@pages/Administration/Administration'),
    meta: { title: 'menu.administration', requiresAuth: true },
    children: [
      {
        name: 'administration-devices',
        path: 'devices',
        component: () => import('@pages/Administration/Devices'),
        meta: { title: 'menu.administration-device', requiresAuth: true },
      },
      {
        name: 'administration-sensors',
        path: 'sensors',
        component: () => import('@pages/Administration/Sensors'),
        meta: { title: 'menu.administration-sensors', requiresAuth: true },
      },
      {
        name: 'administration-users',
        path: 'users',
        component: () => import('@pages/Administration/Users'),
        meta: { title: 'menu.administration-users', requiresAuth: true },
      },
      {
        name: 'administration-user-groups',
        path: 'user-groups',
        component: () => import('@pages/Administration/UserGroups'),
        meta: { title: 'menu.administration-user-groups', requiresAuth: true },
      },
      {
        name: 'administration-devices-types',
        path: 'devices-types',
        component: () => import('@pages/Administration/DevicesTypes'),
        meta: { title: 'menu.administration-devices-types', requiresAuth: true },
      },
    ],
  },
];
