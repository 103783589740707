<template>
  <div>
    <div
      v-if="showLogin"
      class="login"
      :class="brand"
    >
      <div
        class="login__card"
        :class="{ 'full_screen': terms }"
      >
        <div class="login__card__header">
          <div>
            <img
              v-if="brand === 'rejs'"
              class="login__logo"
              src="@assets/images/rejs/logo.png"
            >
            <img
              v-else-if="brand === 'precimet'"
              class="login__logo"
              src="@assets/images/precimet/logo.png"
            >
            <img
              v-else
              class="login__logo"
              src="@assets/images/default/logo.png"
            >
            <img
              v-if="brand === 'rejs'"
              class="login__logo most"
              src="@assets/images/rejs/most.png"
            >
          </div>
          <div
            v-if="!terms"
            class="login__card__header__flags"
          >
            <div
              class="flag en"
              :class="{active: language === 'en'}"
              @click="reloadLocale('en-US')"
            />
            <div
              class="flag pl"
              :class="{active: language === 'pl'}"
              @click="reloadLocale('pl-PL')"
            />
          </div>
        </div>
        <div class="login__card__content">
          <router-view :language="language" />
        </div>
      </div>
    </div>

    <v-app v-else>
      <v-alert
        v-if="(error || exception) && (!unauthorized || !showLogin)"
        type="error"
        tile
      >
        <div class="title">
          {{ 'error.title'|trans }}
        </div>
        <div class="debug">
          <div class="code">
            {{ 'error.code'|trans }}: {{ error }}
          </div>
          <div class="browser">
            {{ 'error.browser'|trans }}: {{ browser.name }} {{ browser.version }}
            ({{ browser.os }})
          </div>
          <div class="exception">
            {{ 'error.exception'|trans }}: {{ exception }}
          </div>
        </div>
      </v-alert>
      <div
        v-else-if="initialized"
        class="application"
      >
        <bkf-guide
          v-if="!showLogin"
          ref="guide"
        />
        <bkf-v-header
          v-if="!showLogin"
          @toggle-menu="toggleMenu"
          @guide="$refs.guide.start('global')"
        />
        <bkf-v-navmenu
          v-if="!showLogin"
          slot="menu"
          ref="menu"
        />
        <v-main>
          <v-container
            class="bkf-container pa-0"
            fluid
          >
            <bkf-v-breadcrumb v-if="!showLogin" />
            <v-col
              cols="12"
              class="py-0"
            >
              <router-view
                :language="language"
              />
            </v-col>
          </v-container>
        </v-main>
      </div>
      <bkf-loader v-else />
    </v-app>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import LocaleManager from '@services/locale';
import { setTranslations } from '@utils/trans';
import translations from '@translations';
import moment from 'moment';
import 'moment/locale/pl';
import { initApi } from '@services/api';
import oauth, { initAuthApi } from '@services/oauth';

let localeManager;

export default {
  data() {
    return {
      language: 'en',
      locale: 'en-US',
      username: '',
      password: '',
      device: '',
      firstTry: true,
      progress: false,
      title: {
        theme: 'default',
        message: 'title.default',
      },
      drawer: true,
    };
  },
  computed: {
    ...mapGetters({
      browser: 'browser',
      error: 'error',
      exception: 'exception',
      unauthorized: 'unauthorized',
      initialized: 'initialized',
    }),
    showLogin() {
      return !oauth.isLogged();
    },
    brand() {
      return CONFIG.brand.name;
    },
    terms() {
      return this.$route.name === 'terms' || this.$route.name === 'terms_en';
    },
  },
  created() {
    initApi();
    initAuthApi();
    localeManager = LocaleManager.get();
    const locale = !['en', 'pl'].includes(localeManager.$language)
      ? this.locale : localeManager.$locale;
    this.setLocale(locale);
  },
  methods: {
    toggleMenu() {
      this.$refs.menu.toggleMenu();
    },
    reloadLocale(locale) {
      this.setLocale(locale);
    },
    setLocale(locale) {
      localeManager.setLocale(locale);
      this.language = localeManager.$language;
      setTranslations(translations[this.language]);
      moment.locale(locale);
    },
  },
};
</script>

<style scoped lang="stylus" src="./Application.styl"></style>

<style lang="stylus" src="../../login/Login.styl"></style>
<style lang="stylus" src="../../login/Brand.styl"></style>
