import Action from './Action';
import Add from './Add';
import AddForm from './AddForm';
import Close from './Close';
import Delete from './Delete';
import Duplicate from './Duplicate';
import Edit from './Edit';
import EditForm from './EditForm';
import Expand from './Expand';
import Export from './Export';
import Launch from './Launch';
import NextPage from './NextPage';
import Options from './Options';
import PreviousPage from './PreviousPage';
import Refresh from './Refresh';

export default {
  install(Vue) {
    Vue.use(Action);
    Vue.use(Add);
    Vue.use(AddForm);
    Vue.use(Close);
    Vue.use(Delete);
    Vue.use(Duplicate);
    Vue.use(Edit);
    Vue.use(EditForm);
    Vue.use(Expand);
    Vue.use(Export);
    Vue.use(Launch);
    Vue.use(NextPage);
    Vue.use(Options);
    Vue.use(PreviousPage);
    Vue.use(Refresh);
  },
};
