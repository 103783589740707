<template>
  <indicator>
    <alert2-icon
      :level="level"
      :size="size"
      :content="content"
    />
  </indicator>
</template>

<script>
import trans from '@utils/trans';
import Indicator from '../Indicator/Indicator.vue';
import Alert2Icon from '../../../../icons/Alert2/Alert2Icon.vue';

export default {
  components: {
    Alert2Icon,
    Indicator,
  },
  props: {
    level: {
      required: true,
      type: String,
    },
    count: {
      required: true,
      type: Number,
    },
    size: {
      type: String,
      default: '24px',
    },
  },
  computed: {
    content() {
      return `${trans('device.indicator.alert-level')} ${this.level}, ${trans('device.indicator.alert-count')} (${this.count})`;
    },
  },
};
</script>
