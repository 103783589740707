import Vue from 'vue';
import Router from 'vue-router';
import oauth from '@services/oauth';
import authRoutes from './authRoutes';
import dashboardRoutes from './dashboardRoutes';

Vue.use(Router);

const router = new Router({
  routes: [
    ...authRoutes,
    ...dashboardRoutes,
    { path: '*', redirect: '/login' }, // Fallback dla nieznanych tras
  ],
});

// Middleware do sprawdzania, czy u�ytkownik jest zalogowany
router.beforeEach((to, from, next) => {
  if (to.path === '/login' && oauth.isLogged()) {
    next('/dashboard');
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!oauth.isLogged()) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
