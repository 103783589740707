import sleep from 'es7-sleep';

/**
 * Sleep method plugin
 */
export default {
  install(Vue) {
    Vue.prototype.$sleep = async (milliseconds) => sleep(milliseconds);
  },
};
