import Vue from 'vue';
import Vuex from 'vuex';
import browserInfo from 'browser-info';
import locale from 'element-ui/lib/locale';
import en from 'element-ui/lib/locale/lang/en';
import pl from 'element-ui/lib/locale/lang/pl';
import LocaleManager from '@services/locale';
import { setTranslations } from '@utils/trans';
import deep from '@utils/deep';
import modules from './modules';

// Use Vuex
Vue.use(Vuex);

const localeManager = LocaleManager.get();

const store = new Vuex.Store({
  state: {
    config: null,
    error: null,
    exception: null,
    initialized: false,
    unauthorized: false,
    loading: null,
  },
  getters: {
    browser: () => browserInfo(),
    config: (s) => s.config,
    error: (s) => s.error,
    exception: (s) => s.exception,
    unauthorized: (s) => s.unauthorized,
    initialized: (s) => s.initialized,
    loading: (s) => s.loading,
  },
  mutations: {
    INIT_CONFIG(state, config) {
      state.config = config;
    },
    BROWSER(state, browser) {
      state.browser = browser;
    },
    ERROR(state, error) {
      state.error = error;
    },
    EXCEPTION(state, exception) {
      state.exception = btoa(exception);
    },
    INITIALIZED(state) {
      state.initialized = true;
    },
    UNAUTHORIZED(state) {
      state.unauthorized = true;
    },
    LOADING(state, moduleName) {
      state.loading = moduleName;
    },
  },
  actions: {
    async initLogin({ commit, dispatch }, { config }) {
      // Init config
      commit('INIT_CONFIG', config);

      await dispatch('loadTranslations');

      // Init themes
      if (config.themes) {
        dispatch('size/init');
        dispatch('colors/setColors', {
          primary: deep(config, 'themes.default.primary'),
          header: deep(config, 'themes.header.primary'),
        });
      }

      // Wait for all promises, then show application
      commit('INITIALIZED');
    },
    async init({ commit, dispatch }, { config }) {
      // Init config
      commit('INIT_CONFIG', config);

      await dispatch('loadTranslations');

      // Init themes
      if (config.themes) {
        dispatch('size/init');
        dispatch('colors/setColors', {
          primary: deep(config, 'themes.default.primary'),
          header: deep(config, 'themes.header.primary'),
        });
      }

      // Initialize dynamic modules
      const inits = await dispatch('loadModules');
      await Promise.all(inits.map((action) => dispatch(action)));

      // Wait for all promises, then show application
      commit('INITIALIZED');
    },
    loading({ commit }, moduleName) {
      commit('LOADING', moduleName);
    },
    /**
     * Dynamically load modules
     */
    async loadModules() {
      const moduleNames = [
        'users',
        'settings',
        'devices',
      ];

      const promises = moduleNames.map((name) => import(/* webpackChunkName: "vuex.[request]" */`./modules/dynamic/${name}`));
      const results = await Promise.all(promises);

      const inits = results.map(({ actions, default: module, moduleName }) => {
        store.registerModule(moduleName, module);

        if (actions && actions.init) {
          return `${moduleName}/${actions.init}`;
        }

        return null;
      });

      return inits.filter((init) => init);
    },
    /**
     * Load translations from webpack chunked modules
     */
    async loadTranslations() {
      let translations = null;
      try {
        const language = localeManager.$language;
        translations = await import(/* webpackChunkName: "lang.[request]" */ `@translations/${language}.yml`);
        locale.use(language === 'pl' ? pl : en);
      } catch (e) {
        translations = await import('@translations/en.yml');
      }
      setTranslations(translations);
      await store.dispatch('loading', 'application');
    },
  },
  modules,
});

/**
 * Export Vuex.Store instance
 */
export default store;
