<template>
  <div>
    <v-radio-group
      v-model="settings.byGroups"
      :mandatory="true"
      :hide-details="true"
      row
      dense
      class="mb-4 mt-2"
    >
      <v-radio
        :label="$trans('monitor.devices')"
        :value="false"
      />
      <v-radio
        :label="$trans('monitor.device-groups')"
        :value="true"
      />
    </v-radio-group>
    <devices-field
      v-show="!settings.byGroups"
      v-model="settings.devices"
      :devices="devices"
      :error="error"
    />
    <device-groups-field
      v-show="settings.byGroups"
      v-model="settings.deviceGroups"
      :device-groups="deviceGroups"
      :error="error"
    />
  </div>
</template>

<script>
import api from '@services/api';
import DeviceGroupsField from './fields/DeviceGroups/DeviceGroups.vue';
import DevicesField from './fields/Devices/Devices.vue';

export default {
  components: {
    DeviceGroupsField,
    DevicesField,
  },
  props: {
    deviceGroups: {
      required: true,
      type: Array,
    },
    devices: {
      required: true,
      type: Array,
    },
    alertRule: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      settings: {
        devices: [],
        deviceGroups: [],
        byGroups: false,
      },
      error: false,
    };
  },
  computed: {
    isEmpty() {
      return this.settings.devices.length === 0 && this.settings.deviceGroups.length === 0;
    },
  },
  watch: {
    isEmpty() {
      this.$emit('empty', this.isEmpty);
    },
  },
  methods: {
    async link() {
      this.error = false;
      const { deviceGroups, devices } = this.settings;

      if (deviceGroups.length === 0 && devices.length === 0) {
        this.error = true;
      }

      if (deviceGroups.length > 0) {
        this.linkDeviceGroups(deviceGroups);
      }
      if (devices.length > 0) {
        this.linkDevices(devices);
      }
    },
    async linkDeviceGroups(ids) {
      const { alertRule } = this;
      await api.patch(`/alertrules/${alertRule.id}/devicegroups/${ids.join(',')}/assign`);
      this.$emit('submitted');
    },
    async linkDevices(ids) {
      const { alertRule } = this;
      await api.patch(`/alertrules/${alertRule.id}/devices/${ids.join(',')}/assign`);
      this.$emit('submitted');
    },
  },
};
</script>
