import axios from 'axios';

const TOKEN_STORAGE_KEY = 'fm-oauth';
let authApi = null;

/**
 * Inicjalizujemy API do autoryzacji z przekazanym `baseUrl`
 */
export const initAuthApi = () => {
  const baseUrl = CONFIG.url.api;
  authApi = axios.create({
    baseURL: baseUrl,
    headers: { 'Content-Type': 'application/json' },
  });
};

// Pobieranie tokenów z localStorage
const getTokens = () => JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY)) || {};

// Zapisywanie tokenów
const saveTokens = (tokens) => {
  localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(tokens));
};

// Usuwanie tokenów
const clearTokens = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
};

// Pobieranie access tokena
const getAccessToken = () => getTokens().access_token;

// Pobieranie refresh tokena
const getRefreshToken = () => getTokens().refresh_token;

// Logowanie
const login = async (username, password) => {
  try {
    const { data } = await authApi.post('/token', {
      client_id: CONFIG.oauth.id,
      client_secret: CONFIG.oauth.secret,
      grant_type: 'password',
      username,
      password,
    });
    saveTokens(data);
    return { success: true };
  } catch (error) {
    return { success: false, error: error.response?.data?.error || 'Błąd logowania' };
  }
};

const isLogged = () => {
  const tokens = getTokens();
  return !!tokens.access_token; // Zwraca `true` jeśli token istnieje, `false` jeśli nie
};

const impersonate = async (data) => {
  localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(data));
};

// Odświeżanie tokena
const refreshToken = async () => {
  const refreshToken2 = getRefreshToken();

  if (!refreshToken2) {
    // console.warn('[auth] Brak refresh_token – czyszczę tokeny');
    clearTokens();
    return false;
  }

  try {
    const { data } = await authApi.post('/token', {
      client_id: CONFIG.oauth.id,
      client_secret: CONFIG.oauth.secret,
      grant_type: 'refresh_token',
      refresh_token: refreshToken2,
    });

    saveTokens(data);
    return true;
  } catch (error) {
    // console.error('[auth] Błąd odświeżania tokena', error?.response || error);
    clearTokens();
    return false;
  }
};

// Wylogowanie
const logout = () => {
  clearTokens();
};

export default {
  login,
  logout,
  refreshToken,
  getAccessToken,
  isLogged,
  initAuthApi,
  impersonate,
};
